<template>
  <div class="box">
    <div class="base_info">
      <div class="title">基础信息</div>
      <div class="personal_info">
        <div class="personal_info_item" style="font-size: 16px; color: rgb(0 137 255)">
          {{ personalData[0].customerName }}
        </div>
        <div class="personal_info_item" style="display: flex">
          <div style="font-size: 18px">账户余额</div>
          <div>
            <div v-for="item in personalData" :key="item.id" class="formatMoney">{{ item.amountFormat }}</div>
          </div>
        </div>
        <div class="personal_info_item">
          <el-button type="warning" @click="RechargePageDialog=true">充值</el-button>
        </div>
      </div>
    </div>
    <div class="recharge_record">
      <div class="title" style="margin-bottom: 20px">充值记录</div>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane name="first" label="充值记录">
          <el-card>
            <div class="search_bar">
              <div class="search_bar_item">
                <span>交易流水号：</span>
                <el-input v-model="search.serialNumber" size="mini" style="width: 200px" />
              </div>
              <div class="search_bar_item">
                <span>状态：</span>
                <el-select v-model="search.status" size="mini" style="width: 200px" clearable>
                  <el-option label="待审核" value="0" />
                  <el-option label="审核通过" value="1" />
                  <el-option label="审核不通过" value="2" />
                </el-select>
              </div>
              <div class="search_bar_item">
                <span>币种：</span>
                <el-select v-model="search.cid" size="mini" style="width: 200px" clearable>
                  <el-option
                    v-for="(item, index) in currencyList"
                    :key="index"
                    :label="item.currencyName"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="search_bar_item">
                <el-button type="primary" size="mini" @click="findTradingRecordByCz">搜索</el-button>
              </div>
            </div>
            <el-table
              :data="rechargeRecordData"
              border
              stripe
            >
              <el-table-column
                label="交易单号"
                prop="tradingNo"
                align="center"
              />
              <el-table-column
                label="金额"
                prop="amount"
                align="center"
              />
              <el-table-column
                label="币种"
                prop="currencyName"
                align="center"
              />
              <el-table-column
                label="交易流水号"
                prop="serialNumber"
                align="center"
              />
              <el-table-column
                label="创建时间"
                prop="createTime"
                align="center"
              />
              <el-table-column
                label="状态"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-show="scope.row.status === '0'">待审核</span>
                  <span v-show="scope.row.status === '1'">审核通过</span>
                  <span v-show="scope.row.status === '2'">待审核不通过</span>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane name="second" label="交易记录">
          <el-card>
            <div class="search_bar">
              <div class="search_bar_item">
                <span>交易单号：</span>
                <el-input v-model="ReSearch.tradingNo" style="width: 200px" size="mini" />
              </div>
              <div class="search_bar_item">
                <span>交易类型：</span>
                <el-select v-model="ReSearch.tradingType" style="width: 200px" size="mini" clearable>
                  <el-option label="充值" :value="1" />
                  <el-option label="支付" :value="2" />
                </el-select>
              </div>
              <div class="search_bar_item">
                <span>币种：</span>
                <el-select v-model="ReSearch.cid" style="width: 200px" size="mini" clearable>
                  <el-option
                    v-for="(item, index) in currencyList"
                    :key="index"
                    :label="item.currencyName"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="search_bar_item">
                <el-button type="primary" size="mini" @click="findTradingRecord">搜索</el-button>
              </div>
            </div>
            <el-table
              :data="transactionRecordData"
              border
              stripe
            >
              <el-table-column
                label="交易单号"
                prop="tradingNo"
                align="center"
              />
              <el-table-column
                label="交易类型"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.tradingType === 1">充值</span>
                  <span v-if="scope.row.tradingType === 2">支付</span>
                </template>
              </el-table-column>
              <el-table-column
                label="交易金额"
                prop="amount"
                align="center"
              />
              <el-table-column
                label="币种"
                prop="currencyName"
                align="center"
              />
              <el-table-column
                label="交易时间"
                prop="createTime"
                align="center"
              />
              <el-table-column
                label="交易备注"
                prop="remark"
                align="center"
              />
              <el-table-column
                label="账户余额"
                prop=""
                align="center"
              />
            </el-table>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="充值"
      :visible.sync="RechargePageDialog"
      width="60%"
    >
      <el-form label-width="150px">
        <el-form-item label="充值流水号：">
          <el-input v-model="ruleForm.serialNumber" style="width: 300px" />
        </el-form-item>
        <el-form-item label="充值金额：">
          <el-input v-model="ruleForm.amount" style="width: 300px" />
          <el-select v-model="ruleForm.cid" placeholder="请选择币种" style="width: 100px" clearable>
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item.currencyName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="请上传支付凭证：">
          <el-upload
            with-credentials
            :action="url"
            :on-success="handlePictureCardPreview"
            :show-file-list="false"
            :on-error="uploadfail"
          >
            <el-button type="primary" size="mini">
              文件上传
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="fileList">
            <template v-for="(item, i) in ruleForm.voucherUrls">
              <el-tag :key="i" style="width:100px" closable @close="fileListDelete(i)">
                <el-tooltip placement="top" effect="light" :content="item.fileName">
                  <a :href="item.absolutePath" class="fileClick" target="_blank">{{ item.fileName }}</a>
                </el-tooltip>
              </el-tag>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="customerPay">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  findCurrAccountBalance,
  customerPay,
  findTradingRecordByCz,
  findTradingRecord
} from '@/api/user.js';
import {
  findCurrencyInfo
} from '@/api/select.js';
export default {
  data() {
    return {
      rechargeRecordData: [],
      url: process.env.VUE_APP_FILEURL,
      currencyList: [],
      transactionRecordData: [],
      activeName: 'first',
      search: {},
      ReSearch: {},
      personalData: [],
      RechargePageDialog: false,
      ruleForm: {
        voucherUrls: []
      }
    };
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.findCurrAccountBalance();
    this.findCurrencyInfo();
    this.findTradingRecord();
    this.findTradingRecordByCz();
  },
  mounted() {

  },
  methods: {
    findCurrAccountBalance() {
      findCurrAccountBalance({})
        .then(res => {
          if (+res.data.code === 0) {
            this.personalData = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    findCurrencyInfo() {
      findCurrencyInfo()
        .then(res => {
          if (+res.data.code === 0) {
            this.currencyList = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    findTradingRecordByCz() {
      findTradingRecordByCz(this.search)
        .then(res => {
          if (+res.data.code === 0) {
            this.rechargeRecordData = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    findTradingRecord() {
      findTradingRecord(this.ReSearch)
        .then(res => {
          if (+res.data.code === 0) {
            this.transactionRecordData = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    handlePictureCardPreview(res) {
      this.ruleForm.voucherUrls.push(res.data);
    },

    uploadfail(err) {
      this.$message.error(err.msg);
    },
    fileListDelete(index) {
      this.ruleForm.voucherUrls.splice(index, 1);
    },
    customerPay() {
      this.$confirm('是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        customerPay(this.ruleForm)
          .then(res => {
            if (+res.data.code === 0) {
              this.findCurrAccountBalance();
              this.findCurrencyInfo();
              this.findTradingRecord();
              this.findTradingRecordByCz();
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.RechargePageDialog = false;
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(err => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style scoped>
  .box {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    margin-top: 30px;
    border-left: 4px solid #559fd1;
    font-size: 20px;
    font-weight: bold;
    color: #404040;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
  }
  .personal_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin: 20px 0;
    border: 2px solid #ccc;
    background-color: #c2e6ff;
    width: 100%;
    height: 100px;
  }
  .personal_info_item {
    width: 33%;
    height: 100%;
    line-height: 100px;
  }
  .formatMoney {
    margin-top: 10px;
    line-height: 30px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #ff9844;
  }
  .search_bar {
    display: flex;
  }
  .search_bar_item {
    margin:0 20px 20px 0;
  }
  .fileClick {
  width: 100%;
  vertical-align: middle;
  text-decoration: none;
  color: #409EFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}
.fileList /deep/ .el-tag__close.el-icon-close {
  right: 10px;
}
</style>
